<template>
  <div :style="style" class="privacy-box" aria-label="개인정보처리방침" tabindex="0">
    <p></p>
    <h2>개인정보처리방침</h2>
    <p>
      <em>
        &lt;영화배급협동조합 씨네소파&gt;('https://cinesopa.kr'이하 '씨네소파 웹사이트')
      </em>
      은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
      이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
    </p>
    <p>
      <em> &lt;영화배급협동조합 씨네소파&gt;('씨네소파 웹사이트') </em>
      은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항을 통하여 공지할 것입니다.
    </p>
    <p>
      ※ 본 방침은부터
      <em>2020년 1월 1일</em>부터 시행됩니다.
    </p>
    <h3>
      1. 개인정보의 처리 목적
    </h3>
    <p>
      <em>&lt;영화배급협동조합 씨네소파&gt;('https://cinesopa.kr'이하 '씨네소파 웹사이트')</em
      >은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는
      사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
    </p>
    <ul>
      <li>가. 배급 의뢰 업무 진행</li>
      <li>나. 상영 신청 업무 진행</li>
    </ul>
    <!-- <h3>
      2. 개인정보 보관 현황
    </h3>
    <ul>
      <li>개인정보 항목 : 이메일, 휴대전화번호, 이름</li>
      <li>
        수집방법 : 홈페이지에서 작성된 내용이 안전한 메일 서버로 전송되어 보관됨
      </li>
      <li>보유기간 : 목적 달성후 지체없이 파기</li>
    </ul> -->
    <h3>
      2. 개인정보의 처리 및 보유 기간
    </h3>
    <p>
      <em>&lt;영화배급협동조합 씨네소파&gt;('씨네소파 웹사이트')</em>은(는) 법령에 따른 개인정보
      보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서
      개인정보를 처리,보유합니다.각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
    </p>
    <h4>
      배급 의뢰 업무 진행
    </h4>
    <ol>
      <li>보유근거 : 정보주체의 동의</li>
      <li>보유기간 : 동의일로부터 목적 달성까지</li>
      <li>보유방법 : 메일 서버에 안전하게 보관</li>
    </ol>
    <h4>
      상영 신청 업무 진행
    </h4>

    <ol>
      <li>보유근거 : 정보주체의 동의</li>
      <li>보유기간 : 동의일로부터 상영 정산 완료까지</li>
      <li>보유방법 : 자체 서버에 안전하게 보관</li>
    </ol>

    <h3>3. 정보주체와 법정대리인의 권리·의무 및 그 행사방법</h3>
    <!-- <h3>4. 정보주체와 법정대리인의 권리·의무 및 그 행사방법</h3> -->
    <p>
      <strong> 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</strong>
    </p>
    <ol>
      <li>
        정보주체는 영화배급협동조합 씨네소파에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구
        등의 권리를 행사할 수 있습니다.
      </li>
      <li>
        제1항에 따른 권리 행사는영화배급협동조합 씨네소파에 대해 개인정보 보호법 시행령
        제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며 영화배급협동조합 씨네소파은(는)
        이에 대해 지체 없이 조치하겠습니다.
      </li>
      <li>
        제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
        있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
        합니다.
      </li>
      <li>
        개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여
        정보주체의 권리가 제한 될 수 있습니다.
      </li>
      <li>
        개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
        경우에는 그 삭제를 요구할 수 없습니다.
      </li>
      <li>
        영화배급협동조합 씨네소파은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
        처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
      </li>
    </ol>
    <!-- <h3>
      5. 처리하는 개인정보의 항목 작성
    </h3> -->
    <h3>
      4. 처리하는 개인정보의 항목 작성
    </h3>
    <p>
      <em>&lt;영화배급협동조합 씨네소파&gt;('https://cinesopa.kr'이하 '씨네소파 웹사이트')</em
      >은(는) 다음의 개인정보 항목을 처리하고 있습니다.
    </p>
    <h4>
      배급 의뢰 업무 진행
    </h4>
    <ul>
      <li>
        필수항목: 신청인 이메일, 신청인 전화번호, 신청인 이름
      </li>
      <li>선택항목: 감독 이메일, 감독 전화번호, 감독 이름</li>
    </ul>
    <h4>
      상영 신청 업무 진행
    </h4>
    <ul>
      <!-- <li>1. &lt;></li> -->
      <li>필수항목 : 신청인 이메일, 신청인 전화번호, 신청인 이름, 상영본 받을 주소</li>
      <li>선택항목 : -</li>
      <li>추가 필요 정보는 별도 메일로 소통합니다.</li>
    </ul>

    <!-- <h3>6. 개인정보의 파기</h3> -->
    <h3>5. 개인정보의 파기</h3>
    <p>
      <strong
        ><em>&lt;영화배급협동조합 씨네소파&gt;('씨네소파 웹사이트')</em>은(는) 원칙적으로 개인정보
        처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은
        다음과 같습니다.</strong
      >
    </p>
    <p></p>
    <ul>
      <li>파기절차: 이용자가 입력한 정보는 목적 달성 후 즉시 파기됩니다.</li>
      <li>
        파기기한: 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터
        5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가
        불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그
        개인정보를 파기합니다.
      </li>
    </ul>

    <!-- <h3>7. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</h3> -->
    <h3>6. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</h3>
    <p>
      영화배급협동조합 씨네소파 은 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키’를 사용하지
      않습니다.
    </p>
    <!-- <h3>8. 개인정보 보호책임자 작성</h3> -->
    <h3>7. 개인정보 보호책임자 작성</h3>
    <p>
      <span>영화배급협동조합 씨네소파(‘https://cinesopa.kr’이하 ‘씨네소파 웹사이트)</span>
      은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
      불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
    </p>
    <ul>
      <li>성명 :성송이</li>
      <li>직책 :이사</li>
      <li>연락처 :010-3577-4970, coop.cinesopa@gmail.com</li>
    </ul>
    <!--
    <p>※ 개인정보 보호 담당부서로 연결됩니다.</p> -->
    <p>
      정보주체께서는 영화배급협동조합 씨네소파(‘https://cinesopa.kr’이하 ‘씨네소파 웹사이트) 의
      서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
      관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 영화배급협동조합
      씨네소파(‘https://cinesopa.kr’이하 ‘씨네소파 웹사이트) 은(는) 정보주체의 문의에 대해 지체 없이
      답변 및 처리해드릴 것입니다.
    </p>
    <!-- <h3>9. 개인정보 처리방침 변경</h3> -->
    <h3>8. 개인정보 처리방침 변경</h3>
    <p>
      이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및
      정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
    </p>
    <!-- <h3>10. 개인정보의 안전성 확보 조치</h3> -->
    <h3>9. 개인정보의 안전성 확보 조치</h3>
    <p>
      <strong>
        <em>&lt;영화배급협동조합 씨네소파&gt;('씨네소파 웹사이트')</em>은(는) 개인정보보호법
        제29조에 따라 다음과 같이 안전성 확보에 필요한 관리적 조치를 하고 있습니다.</strong
      >
    </p>
    <p>
      - 내부관리계획의 수립 및 시행 : 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
      시행하고 있습니다.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  props: ['height'],
  computed: {
    style() {
      if (!this.height) return {};
      return {
        height: `${this.height}px`,
        overflowY: 'scroll',
        border: '1px solid #ddd',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy-box {
  // height: 300px;
  // overflow-y: scroll;
  // border: 1px solid #ddd;
  padding: 0 30px 20px;
}

h2 {
  font-size: 20px;
}
h3 {
  font-size: 16px;
  margin-top: 30px;
}
h4 {
  font-size: 13px;
  font-weight: bold;
}
p,
li {
  font-size: 13px;
}
</style>
<style></style>
